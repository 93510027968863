import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProductModal from "./ProductModal";
import LivePreview from "./LivePreview";
import { SliderPicker } from "react-color";
import {
  fontFamilies,
  fontSizes,
  INITIAL_Font_Size_name,
  INITIAL_nameFontSizeMobile,
  INITIAL_priceFontSizeMobile,
  INITIAL_TITLE_COLOR,
  INITIAL_TITLE_COLOR_PDF,
  INITIAL_TITLE_FONT_FAMILY,
  INITIAL_titleFontSizeMobile,
  sizes,
  Star_Color,
} from "../constant";
import { Button, Form, Tab, Tabs } from "react-bootstrap";
import QRCode from "react-qr-code";
import PdfScreens from "./PdfScreens";
import PdfModal from "./PdfModal";
import SoldOut from "../SVG/SoldOut";

const BannerForm = ({ onFormSubmit }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [background, setBackground] = useState(null);
  const [pdfbackground, setPdfBackground] = useState(null);
  const [title, setTitle] = useState("");
  const [pdfTitle, setPdfTitle] = useState("");
  const [discription, setDiscription] = useState("");
  const [qrText, setQrText] = useState("");
  const [generatedQR, setGeneratedQR] = useState("");
  const [footer, setFooter] = useState("");
  const [pdfFooter, setPdfFooter] = useState("");
  const [product, setProduct] = useState("");
  const [size, setSize] = useState("");
  const [titleColor, setTitleColor] = useState(INITIAL_TITLE_COLOR);
  const [pdfTitleColor, setPdfTitleColor] = useState(INITIAL_TITLE_COLOR_PDF);
  const [starColor, setStarColor] = useState( Star_Color);
  const [nameColor, setNameColor] = useState(INITIAL_TITLE_COLOR);
  const [pdfNameColor, setPdfNameColor] = useState(INITIAL_TITLE_COLOR);
  const [priceColor, setPriceColor] = useState(INITIAL_TITLE_COLOR);
  const [pdfPriceColor, setPdfPriceColor] = useState(INITIAL_TITLE_COLOR);
  const [productDetails, setProductDetails] = useState([]);
  const [pdfProductDetails, setPdfProductDetails] = useState([]);
  const [showFooterInput, setShowFooterInput] = useState(false);
  const [showTC, setShowTC] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Define state for font sizes and font families
  const [titleFontSize, setTitleFontSize] = useState("");
  const [pdftitleFontSize, setPdfTitleFontSize] = useState("");
  const [titleFontSizeMobile, setTitleFontSizeMobile] = useState(
    INITIAL_titleFontSizeMobile
  );
  const [nameFontSizeMobile, setNameFontSizeMobile] = useState(
    INITIAL_nameFontSizeMobile
  );
  const [priceFontSizeMobile, setPriceFontSizeMobile] = useState(
    INITIAL_priceFontSizeMobile
  );

  const [titleFontFamily, setTitleFontFamily] = useState(
    INITIAL_TITLE_FONT_FAMILY
  );
  const [pdfTitleFontFamily, setPdfTitleFontFamily] = useState(
    INITIAL_TITLE_FONT_FAMILY
  );
  const [nameFontSize, setNameFontSize] = useState(INITIAL_Font_Size_name);
  const [pdfnameFontSize, setPdfNameFontSize] = useState(
    INITIAL_Font_Size_name
  );
  const [priceFontSize, setPriceFontSize] = useState("");
  const [pdfPriceFontSize, setPdfPriceFontSize] = useState("");
  const [price, setPrice] = useState("");
  const [PdfPrice, setPdfPrice] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [customPrice, setCustomPrice] = useState("");
  const [isPdfEditing, setIsPdfEditing] = useState(false);
  const [pdfcustomPrice, setPdfCustomPrice] = useState("");
  const [regularPrice, setRegularPrice] = useState("");
  const [isEditRegular, setIsEditRegular] = useState(false);
  const [isPdfGeneration, setIsPdfGeneration] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  /**
   * Function to resize an image file and add optional footer text and T&C text.
   * The function reads the image, resizes it to fit within specified dimensions
   * while maintaining the aspect ratio, and optionally adds footer text and
   * T&C text before returning the resized image file.
   *
   * @param {File} file - The image file to be resized.
   * @returns {Promise<File>} - A promise that resolves with the resized image file.
   *
   * The resizing process includes:
   * - Reading the image file using FileReader.
   * - Creating an Image object and setting its source to the file data.
   * - Calculating the target width and height based on the selected size
   *   and aspect ratio.
   * - Drawing the resized image onto a canvas.
   *
   * Optional features:
   * - Adding footer text: The text is wrapped and centered at the bottom of
   *   the image if the `footer` variable is provided.
   * - Adding "T&C" text: If the `showTC` variable is true, the text "T&C" is
   *   added in the bottom-left corner of the image.
   *
   * Finally, the canvas content is converted to a Blob and wrapped in a File object,
   * which is then returned by resolving the promise.
   */

  const handleImageResize = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const selectedSize = sizes.find((s) => s.name === size);
          const canvas = document.createElement("canvas");
          const aspectRatio =
            selectedSize.dimensions.width / selectedSize.dimensions.height;
          let targetWidth, targetHeight;

          if (img.width / img.height > aspectRatio) {
            targetHeight = selectedSize.dimensions.height;
            targetWidth = targetHeight * (img.width / img.height);
          } else {
            targetWidth = selectedSize.dimensions.width;
            targetHeight = targetWidth * (img.height / img.width);
          }

          canvas.width = selectedSize.dimensions.width;
          canvas.height = selectedSize.dimensions.height;
          const ctx = canvas.getContext("2d");

          const xOffset = (selectedSize.dimensions.width - targetWidth) / 2;
          const yOffset = (selectedSize.dimensions.height - targetHeight) / 2;

          ctx.drawImage(img, xOffset, yOffset, targetWidth, targetHeight);

          // Function to wrap text
          const wrapText = (ctx, text, x, y, maxWidth, lineHeight) => {
            const words = text.split(" ");
            let line = "";
            for (let n = 0; n < words.length; n++) {
              const testLine = line + words[n] + " ";
              const metrics = ctx.measureText(testLine);
              const testWidth = metrics.width;
              if (testWidth > maxWidth && n > 0) {
                ctx.fillText(line, x, y);
                line = words[n] + " ";
                y += lineHeight;
              } else {
                line = testLine;
              }
            }
            ctx.fillText(line, x, y);
          };

          // Add footer text
          if (footer) {
            ctx.font = `28px ${titleFontFamily}`;
            ctx.fillStyle = titleColor;
            ctx.textAlign = "center";
            const maxWidth = canvas.width - 20; // Set max width for text
            const lineHeight = 30;
            const startY = canvas.height - 40;
            wrapText(
              ctx,
              footer,
              canvas.width / 2,
              startY,
              maxWidth,
              lineHeight
            );
          }

          // Add T&C text if checkbox is checked
          if (showTC) {
            ctx.font = `35px ${titleFontFamily}`;
            ctx.fillStyle = titleColor;
            ctx.textAlign = "left";
            ctx.fillText("T&C", 10, canvas.height - 10); // Position the T&C text
          }

          canvas.toBlob((blob) => {
            const resizedFile = new File([blob], file.name, {
              type: file.type,
            });
            resolve(resizedFile);
          }, file.type);
        };
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title.trim()) {
      toast.error("Title is required.");
      return;
    }

    if (!product.trim()) {
      toast.error("Product name is required.");
      return;
    }

    if (!size) {
      toast.error("Size selection is required.");
      return;
    }

    let resizedImage = null;
    if (pdfbackground) {
      resizedImage = await handleImageResize(pdfbackground);
    }

    const selectedPdfProduct = pdfProductDetails.find(
      (p) => p.name?.toLowerCase() === product.toLowerCase()
    );
    if (!selectedPdfProduct) {
      toast.error("Invalid product selection.");
      return;
    }

    onFormSubmit({
      background: resizedImage,
      title,
      product: selectedPdfProduct,
      size,
      titleColor,
      nameColor,
      priceColor,
      titleFontSize,
      titleFontFamily,
      nameFontSize,
      priceFontSize,
      customPrice: customPrice,
    });
  };

  const randomBorderRadius = Math.floor(Math.random() * 15) + "px";
  /**
   * Function to handle the selection of a product from a list.
   * When a product is selected, this function updates the state with
   * the selected product's name and details, and then closes the modal.
   *
   * @param {Object} selectedProduct - The product object that has been selected.
   *
   * The function performs the following steps:
   * - Updates the `product` state with the name of the selected product.
   * - Updates the `productDetails` state with an array containing the selected product.
   * - Closes the modal by setting the `isModalOpen` state to false.
   */

  const handleSelectProduct = (selectedProduct) => {
    setProduct(selectedProduct.name);
    setProductDetails([selectedProduct]); // Set the selected product details
    setIsModalOpen(false); // Close the modal
  };

  const [pdfIsModalOpen, setPdfIsModalOpen] = useState(false);
  const [pdfProduct, setPdfProduct] = useState("");

  const handlePdfSelectProduct = (selectedPdfProduct) => {
    setPdfProduct(selectedPdfProduct.name);
    setPdfProductDetails([selectedPdfProduct]); // Set the selected product details
    setPdfIsModalOpen(false);
  };

  useEffect(() => {
    if (!isEditing) {
      setCustomPrice(price);
    }
  }, [isEditing, price]);

  const handlePriceChange = (e) => {
    setCustomPrice(e.target.value);
  };
  useEffect(() => {
    if (!isPdfEditing) {
      setPdfCustomPrice(PdfPrice);
    }
  }, [isPdfEditing, PdfPrice]);

  const handlePdfPriceChange = (e) => {
    setPdfCustomPrice(e.target.value);
  };

  const handleBlur = () => {
    if (!isNaN(customPrice)) {
      setPrice(parseFloat(customPrice));
    }
    setIsEditing(false);
  };
  const handlePdfBlur = () => {
    if (!isNaN(pdfcustomPrice)) {
      setPdfPrice(parseFloat(pdfcustomPrice));
    }
    setIsPdfEditing(false);
  };

  const handleGenerateQR = () => {
    setGeneratedQR(qrText);
  };
  const handleRegularPriceChange = (e) => {
    setRegularPrice(e.target.value); // Correctly update the price
  };

  const handleRegularBlur = () => {
    setIsEditRegular(false);
  };

  const [useUploadedBackground, setUseUploadedBackground] = useState(true);

  const handleCheckboxChange = () => {
    setUseUploadedBackground(!useUploadedBackground);
  };
  const [activeTab, setActiveTab] = useState("livePreview"); // Default to 'livePreview'
  const [showPreviews, setShowPreviews] = useState(true);

  const handleTabSelect = (key) => {
    setActiveTab(key); // Update the activeTab state
    if (key === "home") {
      setShowPreviews(false); // Hide previews for 'home' tab
    } else {
      setShowPreviews(true); // Show previews for other tabs
    }
  };
  const [description, setDescription] = useState("");

  const handleDescriptionChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= 400) {
      setDescription(inputValue);
    }

    // Adjust height of the textarea dynamically
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const handleCheckSoldOut = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <>
      <div className="banner-generator-main">
        <div className="form-pnl">
          <div className="scrollable-form-container">
            <Tabs
              id="controlled-tab-example"
              activeKey={activeTab}
              onSelect={(k) => handleTabSelect(k)}
              className="mb-3 custom-tabs justify-content-center"
            >
              <Tab
                eventKey="livePreview"
                title="Banner Generation"
                onClick={() => handleTabSelect("livePreview")}
              >
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Upload Background Image:</Form.Label>

                    <Form.Control
                      className="reg-btn-notification big w-100 br-rad colorBorder "
                      type="file"
                      accept="image/*"
                      onChange={(e) => setBackground(e.target.files[0])}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Font Family:</Form.Label>
                    <Form.Select
                      required
                      className="form-control"
                      aria-label="Default select example"
                      value={titleFontFamily}
                      onChange={(e) => setTitleFontFamily(e.target.value)}
                    >
                      {fontFamilies?.map((family) => (
                        <option key={family} value={family}>
                          {family}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Enter Title:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Select Title Font Size:</Form.Label>

                    <Form.Select
                      required
                      className="form-control"
                      aria-label="Default select example"
                      value={titleFontSize}
                      onChange={(e) => setTitleFontSize(e.target.value)}
                    >
                      {fontSizes?.map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Title Color:</Form.Label>
                    <Form.Control
                      style={{ width: "100%" }}
                      type="color"
                      value={titleColor}
                      onChange={(e) => setTitleColor(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Product</Form.Label>
                    <Button
                      className="reg-btn mb-3 big block button-border"
                      onClick={() => setIsModalOpen(true)}
                    >
                      Select Product
                    </Button>

                    <div className="mt-2">
                      {product && (
                        <Form.Control
                          type="text"
                          value={product}
                          readOnly
                          onClick={() => setIsModalOpen(true)}
                        />
                      )}
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Select Product Name Font Size:</Form.Label>
                    <Form.Select
                      required
                      className="form-control"
                      aria-label="Default select example"
                      value={nameFontSize}
                      onChange={(e) => setNameFontSize(e.target.value)}
                    >
                      {fontSizes?.map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Product Name Color:</Form.Label>
                    <Form.Control
                      style={{ width: "100%" }}
                      type="color"
                      value={nameColor}
                      onChange={(e) => setNameColor(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Select Price Font Size:</Form.Label>
                    <Form.Select
                      required
                      className="form-control"
                      aria-label="Default select example"
                      value={priceFontSize}
                      onChange={(e) => setPriceFontSize(e.target.value)}
                    >
                      {fontSizes?.map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Select Price Color:</Form.Label>
                    <Form.Control
                      style={{ width: "100%" }}
                      type="color"
                      value={priceColor}
                      onChange={(e) => setPriceColor(e.target.value)}
                    />
                  </Form.Group>
                  <div>
                    {productDetails?.length > 0 &&
                      productDetails?.find(
                        (p) => p.name.toLowerCase() === product.toLowerCase()
                      ) && (
                        <>
                          {(() => {
                            const selectedProduct = productDetails?.find(
                              (p) =>
                                p.name.toLowerCase() === product.toLowerCase()
                            );
                            const adjustedPrice =
                              parseFloat(selectedProduct?.price) * 0.01; // Ensure it's a float

                            // Log the values for debugging purposes
                            return (
                              <>
                                <div>
                                  <Form.Label>Custom Price</Form.Label>
                                  {isEditing ? (
                                    <Form.Control
                                      type="number"
                                      value={customPrice}
                                      onChange={handlePriceChange}
                                      onBlur={handleBlur}
                                      className="mb-3"
                                      autoFocus
                                    />
                                  ) : (
                                    <Form.Control
                                      value={`$${
                                        customPrice
                                          ? parseFloat(customPrice).toFixed(2)
                                          : adjustedPrice.toFixed(2)
                                      }`}
                                      onClick={() => setIsEditing(true)}
                                      readOnly
                                    />
                                  )}
                                </div>
                              </>
                            );
                          })()}
                        </>
                      )}
                  </div>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Select Title Mobile NFT font size:</Form.Label>

                    <Form.Select
                      required
                      className="form-control"
                      aria-label="Default select example"
                      value={titleFontSizeMobile}
                      onChange={(e) => setTitleFontSizeMobile(e.target.value)}
                    >
                      {fontSizes?.map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Select Name Mobile NFT font size:</Form.Label>

                    <Form.Select
                      required
                      className="form-control"
                      aria-label="Default select example"
                      value={nameFontSizeMobile}
                      onChange={(e) => setNameFontSizeMobile(e.target.value)}
                    >
                      {fontSizes.map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Select Price Mobile NFT font size:</Form.Label>

                    <Form.Select
                      required
                      className="form-control"
                      aria-label="Default select example"
                      value={priceFontSizeMobile}
                      onChange={(e) => setPriceFontSizeMobile(e.target.value)}
                    >
                      {fontSizes?.map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <div>
                    <Form.Group className="mb-3">
                      <Form.Label>QR Generator:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter QR Text"
                        value={qrText}
                        onChange={(e) => setQrText(e.target.value)}
                      />
                      <Button
                        className="reg-btn mb-3 big block button-border"
                        onClick={handleGenerateQR}
                      >
                        Generate QR
                      </Button>
                    </Form.Group>
                  </div>
                  <Form.Group className="mb-3">
                    {["checkbox"].map((type) => (
                      <div key={`default-${type}`} className="mb-3">
                        <Form.Check // prettier-ignore
                          type={type}
                          id={`default-${type}`}
                          label={`Show Footer Input`}
                          checked={showFooterInput}
                          onChange={(e) => setShowFooterInput(e.target.checked)}
                        />
                        {showFooterInput && (
                          <div className="footer-container">
                            <Form.Label>Enter Footer:</Form.Label>
                            <Form.Control
                              type="text"
                              value={footer}
                              onChange={(e) => setFooter(e.target.value)}
                            />
                          </div>
                        )}

                        <Form.Check
                          type={type}
                          label={`T & C`}
                          id={`disabled-default-${type}`}
                          checked={showTC}
                          onChange={(e) => setShowTC(e.target.checked)}
                        />
                      </div>
                    ))}
                    {/* <Form.Check
                      type="checkbox"
                      label={
                        <span onClick={(e) => e.preventDefault()}>
                          Add Soldout Tag
                        </span>
                      }
                      checked={useUploadedBackground}
                      onChange={handleCheckboxChange}
                      className="mt-3"
                    /> */}
                  </Form.Group>
                  <ToastContainer />
                </Form>
              </Tab>
              <Tab
                eventKey="pdfGeneration"
                title="Pdf Generation"
                onClick={() => handleTabSelect("pdfGeneration")}
              >
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Upload Background Image:</Form.Label>

                    <Form.Control
                      className="reg-btn-notification big w-100 br-rad colorBorder "
                      type="file"
                      accept="image/*"
                      onChange={(e) => setPdfBackground(e.target.files[0])}
                    />
                    <Form.Check
                      type="checkbox"
                      label={
                        <span onClick={(e) => e.preventDefault()}>
                          Use uploaded background image
                        </span>
                      }
                      checked={useUploadedBackground}
                      onChange={handleCheckboxChange}
                      className="mt-3"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Font Family:</Form.Label>
                    <Form.Select
                      required
                      className="form-control"
                      aria-label="Default select example"
                      value={pdfTitleFontFamily}
                      onChange={(e) => setPdfTitleFontFamily(e.target.value)}
                    >
                      {fontFamilies?.map((family) => (
                        <option key={family} value={family}>
                          {family}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Enter Title:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Title"
                      value={pdfTitle}
                      onChange={(e) => setPdfTitle(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Select Title Font Size:</Form.Label>

                    <Form.Select
                      required
                      className="form-control"
                      aria-label="Default select example"
                      value={pdftitleFontSize}
                      onChange={(e) => setPdfTitleFontSize(e.target.value)}
                    >
                      {fontSizes?.map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Title Color:</Form.Label>
                    <Form.Control
                      style={{ width: "100%" }}
                      type="color"
                      value={pdfTitleColor}
                      onChange={(e) => setPdfTitleColor(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Product</Form.Label>
                    <Button
                      className="reg-btn mb-3 big block button-border"
                      onClick={() => setPdfIsModalOpen(true)}
                    >
                      Select Product
                    </Button>

                    <div className="mt-2">
                      {pdfProduct && (
                        <Form.Control
                          type="text"
                          value={pdfProduct}
                          onClick={() => setPdfIsModalOpen(true)}
                        />
                      )}
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Select Product Name Font Size:</Form.Label>
                    <Form.Select
                      required
                      className="form-control"
                      aria-label="Default select example"
                      value={pdfnameFontSize}
                      onChange={(e) => setPdfNameFontSize(e.target.value)}
                    >
                      {fontSizes?.map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Product Name Color:</Form.Label>
                    <Form.Control
                      style={{ width: "100%" }}
                      type="color"
                      value={pdfNameColor}
                      onChange={(e) => setPdfNameColor(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Price Color:</Form.Label>
                    <Form.Control
                      style={{ width: "100%" }}
                      type="color"
                      value={pdfPriceColor}
                      onChange={(e) => setPdfPriceColor(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Star Color:</Form.Label>
                    <Form.Control
                      style={{ width: "100%" }}
                      type="color"
                      value={starColor}
                      onChange={(e) => setStarColor(e.target.value)}
                    />
                  </Form.Group>

                  <div>
                    {pdfProductDetails?.length > 0 &&
                      pdfProductDetails?.find(
                        (p) =>
                          p.name.toLowerCase() === pdfProduct?.toLowerCase()
                      ) && (
                        <>
                          {(() => {
                            const selectedPdfProduct = pdfProductDetails.find(
                              (p) =>
                                p.name.toLowerCase() ===
                                pdfProduct.toLowerCase()
                            );
                            const adjustedPrice =
                              selectedPdfProduct.price * 0.01; // Calculate the adjusted price

                            return (
                              <>
                                <div>
                                  <Form.Label>Custom Price</Form.Label>
                                  {isPdfEditing ? (
                                    <Form.Control
                                      type="number"
                                      value={pdfcustomPrice}
                                      onChange={handlePdfPriceChange}
                                      onBlur={handlePdfBlur}
                                      className="mb-3"
                                      autoFocus
                                    />
                                  ) : (
                                    <Form.Control
                                      value={`$${
                                        pdfcustomPrice
                                          ? pdfcustomPrice
                                          : adjustedPrice.toFixed(2)
                                      }`}
                                      onClick={() => setIsPdfEditing(true)}
                                    />
                                  )}
                                </div>
                              </>
                            );
                          })()}
                        </>
                      )}
                  </div>
                  <Form.Group className="mb-3">
                    <Form.Label className="Discription_">
                      Add Description:
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter Your Title"
                      value={description}
                      onChange={(e) => handleDescriptionChange(e)}
                      rows={1}
                      style={{ resize: "none", overflow: "hidden" }}
                    />
                    <small>{description?.length}/400 characters</small>
                  </Form.Group>

                  <div>
                    <Form.Label>Regular Price</Form.Label>
                    {isEditRegular ? (
                      <Form.Control
                        type="number"
                        value={regularPrice}
                        onChange={handleRegularPriceChange}
                        onBlur={handleRegularBlur}
                        className="mb-3"
                        autoFocus
                      />
                    ) : (
                      <Form.Control
                        value={regularPrice ? `$${regularPrice}` : ""}
                        onClick={() => setIsEditRegular(true)}
                        placeholder="Enter Regular Price"
                      />
                    )}
                  </div>

                  <Form.Group className="mb-3">
                    {["checkbox"].map((type) => (
                      <div key={`default-${type}`} className="mb-3">
                        <Form.Check
                          type={type}
                          id={`default-${type}`}
                          label={
                            <span onClick={(e) => e.preventDefault()}>
                              Show Footer Input
                            </span>
                          }
                          checked={showFooterInput}
                          onChange={(e) => setShowFooterInput(e.target.checked)}
                        />
                        {showFooterInput && (
                          <div className="footer-container">
                            <Form.Label>Enter Footer:</Form.Label>
                            <Form.Control
                              type="text"
                              value={pdfFooter}
                              onChange={(e) => setPdfFooter(e.target.value)}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                    <div>
                      <Form.Check
                        type="checkbox"
                        label={
                          <span onClick={(e) => e.preventDefault()}>
                            Add Soldout Tag
                          </span>
                        }
                        className="mt-3"
                        onChange={handleCheckSoldOut}
                      />
                    </div>
                  </Form.Group>
                  <ToastContainer />
                </Form>
              </Tab>
            </Tabs>
          </div>
        </div>
        <div className="banner-pnl">
          {activeTab === "pdfGeneration" && (
            <PdfScreens
              activeTab={activeTab}
              pdfbackground={pdfbackground}
              title={pdfTitle}
              pdfProduct={pdfProduct}
              titleFontSize={pdftitleFontSize}
              size={size}
              isChecked={isChecked}
              useUploadedBackground={useUploadedBackground}
              footer={pdfFooter}
              titleColor={pdfTitleColor}
              starColor={starColor}
              nameColor={pdfNameColor}
              pdfPriceColor={pdfPriceColor}
              regularPrice={regularPrice}
              description={description}
              pdfTitleFontFamily={pdfTitleFontFamily}
              titleFontFamily={pdfTitleFontFamily}
              nameFontSize={pdfnameFontSize}
              priceFontSize={pdfPriceFontSize}
              pdfcustomPrice={pdfcustomPrice}
              pdfProductDetails={pdfProductDetails}
            />
          )}
          {activeTab === "livePreview" && (
            <LivePreview
              background={background}
              title={title}
              product={product}
              size={size}
              useUploadedBackground={useUploadedBackground}
              footer={footer}
              generatedQR={generatedQR}
              showPreviews={showPreviews}
              titleColor={titleColor}
              nameColor={nameColor}
              priceColor={priceColor}
              priceFontSizeMobile={priceFontSizeMobile}
              qrText={qrText}
              regularPrice={regularPrice}
              discription={discription}
              nameFontSizeMobile={nameFontSizeMobile}
              titleFontSize={titleFontSize}
              titleFontSizeMobile={titleFontSizeMobile}
              priceFontSize={priceFontSize}
              titleFontFamily={titleFontFamily}
              nameFontSize={nameFontSize}
              showTC={showTC}
              customPrice={customPrice}
              productDetails={productDetails}
            />
          )}
        </div>
      </div>

      {activeTab === "pdfGeneration" ? (
        <PdfModal
          isOpen={pdfIsModalOpen}
          onRequestClose={() => setPdfIsModalOpen(false)}
          onSelectProduct={handlePdfSelectProduct}
        />
      ) : (
        <ProductModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          onSelectProduct={handleSelectProduct}
        />
      )}
    </>
  );
};

export default BannerForm;
