import React, { useEffect, useState } from "react";
import { downloadSizes } from "../constant";
import Starssvg from "../SVG/Starssvg";
import CleanBG from "../SVG/CleanBG";
import SoldOut from "../SVG/SoldOut";

function A4Modl({
  show,
  handleClose,
  background,
  title,
  titleColor,
  titleFontSize,
  regularPrice,
  starColor,
  footer,
  isChecked,
  showFooterInput,
  pdfPriceColor,
  description,
  useUploadedBackground,
  nameFontSize,
  processedImage,
  pdfProductDetails,
  titleFontFamily,
  pdfcustomPrice,
  nameColor,
  pdfbackground,
  bannerType,
}) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (show) {
      setVisible(true);
    } else {
      setTimeout(() => setVisible(false), 300);
    }
  }, [show]);

  if (!visible) return null;

  const styles = downloadSizes[bannerType] || {};
  const productDetailStyles = styles.productDetails || {};

  return (
    <div className={`modelContainer ${bannerType}`}>
      <div
        className={`modal-overlay ${show ? "show" : "hide"}`}
        onClick={handleClose}
      >
        <div
          className={`modal-content-live ${show ? "fade-in" : "fade-out"}`}
          onClick={(e) => e.stopPropagation()}
          style={{
            ...styles,
            backgroundImage: `url(${
              useUploadedBackground && pdfbackground
                ? pdfbackground instanceof Blob
                  ? URL.createObjectURL(pdfbackground)
                  : pdfbackground
                : CleanBG
            })`,
          }}
        >
          <div>
            <h1
              style={{
                color: titleColor,
                fontFamily: titleFontFamily,
                fontSize: titleFontSize,
                textAlign: "center",
                marginBottom: "20px",
              }}
              className="titlemodel"
            >
              {title}
            </h1>
          </div>
          <div
            className="product-details-container"
            style={productDetailStyles}
          >
            {pdfProductDetails?.map((product, index) => {
              const priceInDollars =
                (pdfcustomPrice || product.price) / 100;
              const formattedPrice = priceInDollars.toFixed(2);

              return (
                <div key={index}>
                  {product.name && (
                    <div
                      className="a44-nameTag-Modal"
                      style={{
                        fontSize: nameFontSize,
                        color: nameColor,
                        fontFamily: titleFontFamily,
                      }}
                    >
                      {product.name}
                    </div>
                  )}

                  {description && (
                    <div
                      className="a44-description"
                      style={{
                        fontFamily: titleFontFamily,
                      }}
                    >
                      {description}
                    </div>
                  )}

                  <div className="price-container">
                    <Starssvg starColor={starColor} />
                    {(pdfcustomPrice || product.price) && (
                      <div
                        className="a44-priceTag"
                        style={{ color: pdfPriceColor }}
                      >
                        <p>Regular Price</p>
                        <div className="price-wrapper">
                          <div className="price-slash"></div>
                          <div className="RegularPrice-pdf">${regularPrice}</div>
                        </div>
                        <p>Only for Today</p>
                        <div className="Formated_Price_pdf">
                          {pdfcustomPrice
                            ? `$${pdfcustomPrice}`
                            : `$${formattedPrice}`}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {isChecked && (
            <div
              className="sold-out-wrapper"
              style={{
                position: "absolute",
                top: "65%",
                left: "50%",
                height: 700,
                width: 700,
                transform: "translate(-50%, -50%)",
                zIndex: 10,
              }}
            >
              <SoldOut />
            </div>
          )}

          {processedImage && (
            <div>
              <img
                src={processedImage}
                alt="Product"
                className="a44-transparent-image"
              />
            </div>
          )}
          <div
            className="footer-container"
            style={{
              fontFamily: titleFontFamily,
            }}
          >
            {(footer || showFooterInput) && (
              <div className="footer-stylemodel-a4">{footer}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default A4Modl;
