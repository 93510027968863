import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import {
  downloadSizes,
  placeholderurldesktop,
  placeholderurllandscape,
  placeholderurlmobile,
  placeholderurlMobileNFT,
  placeholderurlpotrait,
  previewSizes,
  REMOVE_BG,
} from "../constant";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { Button } from "react-bootstrap";
import Previewmodal from "./Previewmodal";
import ModelMobileNFT from "./ModelMobileNFT";
import ProductDetailMobile from "./ProductDetailMobile";
import LandscrapeModel from "./LandscrapeModel";
import QRCode from "react-qr-code";
import CustomLoader from "../Components/CustomeLoader";

const LivePreview = ({
  background,
  title,
  titleColor,
  titleFontSize,
  priceFontSize,
  footer,
  titleFontSizeMobile,
  nameFontSizeMobile,
  generatedQR,
  priceFontSizeMobile,
  showPreviews,
  showFooterInput,
  showTC,
  nameFontSize,
  productDetails,
  titleFontFamily,
  customPrice,
  discription,
  nameColor,
  priceColor,
}) => {
  const [processedImage, setProcessedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const imgRefs = useRef([]);

  useEffect(() => {
    const removeBackground = async () => {
      setLoading(true);
      try {
        const imageUrl =
          productDetails[0]?.imageurl instanceof Blob
            ? URL.createObjectURL(productDetails[0]?.imageurl)
            : productDetails[0]?.imageurl;

        const response = await axios.get(REMOVE_BG, {
          params: { url: imageUrl },
          responseType: "blob",
        });
        const url = URL.createObjectURL(response.data);
        setProcessedImage(url);
      } catch (error) {
        toast.error("Image Not Found");
      } finally {
        setLoading(false);
      }
    };

    if (productDetails && productDetails[0]?.imageurl) {
      removeBackground();
    }
  }, [productDetails]);

  const downloadAllBannersAsPng = async () => {
    setDownloadLoading(true); // Start loading
    const imgElements = imgRefs.current;

    for (let i = 0; i < imgElements.length; i++) {
      const img = imgElements[i];
      if (!img) continue;

      const bannerType = img.getAttribute("data-banner-type");
      const resolution = downloadSizes[bannerType];

      const originalStyle = {
        width: img.style.width,
        height: img.style.height,
      };

      img.style.width = resolution.width;
      img.style.height = resolution.height;

      const canvas = await html2canvas(img, {
        useCORS: true,
        allowTaint: true,
        scale: 1,
        backgroundColor: "#ffffff",
      });

      img.style.width = originalStyle.width;
      img.style.height = originalStyle.height;

      canvas.toBlob((blob) => {
        if (blob) {
          saveAs(blob, `Downloaded-banner-${i + 1}.png`);
        }
      }, "image/png");
    }

    setDownloadLoading(false); // End loading
  };

  const [currentBannerType, setCurrentBannerType] = useState("");

  const handleBannerClick = (bannerType) => {
    setCurrentBannerType(bannerType);
    setShowModal(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="scrollable-form-container-live">
        {showPreviews && (
          <div>
            <h3 className="banner-header">- Greenline Mobile</h3>
            <div
              className="banner-preview greenline-mobile"
              style={{
                position: "relative",
                ...previewSizes.Greenline_Mobile,
                backgroundImage: `url(${
                  background
                    ? background instanceof Blob
                      ? URL.createObjectURL(background)
                      : background
                    : placeholderurlmobile
                })`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
              ref={(el) => (imgRefs.current[0] = el)}
              data-banner-type="Greenline_Mobile"
              onClick={() => handleBannerClick("Greenline_Mobile")}
            >

              <div className="banner-content">
                {title && (
                  <div
                    className="live-title-mobile"
                    style={{
                      fontSize: titleFontSize,
                      color: titleColor,
                      fontFamily: titleFontFamily,
                    }}
                  >
                    {title}
                  </div>
                )}
                {loading ? (
                  <div className="loading-styling">
                    <ClipLoader
                      color={"white"}
                      loading={loading}
                      alignContent="center"
                      size={50}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                ) : (
                  <div className="products-container-mobile">
                    {productDetails?.map((product, index) => {
                      // Convert price from cents to dollars and format it
                      const priceInDollars =
                        (customPrice || product.price) / 100;
                      const formattedPrice = priceInDollars.toFixed(2);

                      return (
                        <div key={index} className="product-details-mobile">
                          {processedImage && (
                            <img
                              src={processedImage}
                              alt={product.name}
                              className="product-image"
                            />
                          )}
                          {product?.name && (
                            <div
                              style={{
                                fontSize: nameFontSize,
                                color: nameColor,
                                fontFamily: titleFontFamily,
                              }}
                            >
                              {product.name}
                            </div>
                          )}
                          {(customPrice || priceInDollars) && (
                            <div
                              style={{
                                fontSize: priceFontSize,
                                color: priceColor,
                                fontFamily: titleFontFamily,
                              }}
                            >
                              ${customPrice || formattedPrice}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
                {(footer || showFooterInput) && (
                  <div
                    className="footer-style"
                    style={{
                      color: titleColor,
                      fontFamily: titleFontFamily,
                    }}
                  >
                    {footer}
                  </div>
                )}
                {showTC && (
                  <div
                    className="T-and-C"
                    style={{
                      color: titleColor,
                      fontFamily: titleFontFamily,
                    }}
                  >
                    T & C
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <h3 className="banner-header">- Greenline Desktop</h3>
        <div
          className="banner-preview greenline-desktop"
          style={{
            position: "relative",
            ...previewSizes.Greenline_Desktop,
            backgroundImage: `url(${
              background
                ? background instanceof Blob
                  ? URL.createObjectURL(background)
                  : background
                : placeholderurldesktop
            })`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          ref={(el) => (imgRefs.current[1] = el)}
          data-banner-type="Greenline_Desktop"
          onClick={() => handleBannerClick("Greenline_Desktop")}
        >
          {title && (
            <div
              className="live-title"
              style={{
                fontSize: titleFontSize,
                color: titleColor,
                fontFamily: titleFontFamily,
              }}
            >
              {title}
            </div>
          )}

          {loading ? (
            <div className="loading-styling">
              <ClipLoader
                color={"white"}
                loading={loading}
                alignContent="center"
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <div className="live-title">
              {productDetails?.map((product, index) => {
                // Calculate price in dollars and format it
                const priceInDollars = (customPrice || product.price) / 100;
                const formattedPrice = priceInDollars?.toFixed(2);

                return (
                  <div key={index} className="Desktop-imgcont">
                    {processedImage && (
                      <img
                        src={processedImage}
                        alt={product.name}
                        className="Desktop-transparent-image"
                      />
                    )}
                    {product?.name && (
                      <div
                        className="Desktop-nameTag"
                        style={{
                          fontSize: nameFontSize,
                          color: nameColor,
                          fontFamily: titleFontFamily,
                        }}
                      >
                        {product.name}
                      </div>
                    )}
                    {(customPrice || product.price) && (
                      <div
                        className="Desktop-priceTag"
                        style={{
                          fontSize: priceFontSize,
                          color: priceColor,
                          fontFamily: titleFontFamily,
                        }}
                      >
                        ${customPrice || formattedPrice}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}

          {(footer || showFooterInput) && (
            <div
              className="footer-style"
              style={{
                color: titleColor,
                fontFamily: titleFontFamily,
              }}
            >
              {footer}
            </div>
          )}
          {showTC && (
            <div
              className="T-and-C"
              style={{
                color: titleColor,
                fontFamily: titleFontFamily,
              }}
            >
              T & C
            </div>
          )}
        </div>

        <h3 className="banner-header">- Menu Board TV Landscape</h3>
        <div
          className="banner-preview greenline-mobile"
          style={{
            position: "relative",
            ...previewSizes.Menu_Board_TV_Landscape,
            backgroundImage: `url(${
              background
                ? background instanceof Blob
                  ? URL.createObjectURL(background)
                  : background
                : placeholderurllandscape
            })`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          ref={(el) => (imgRefs.current[2] = el)}
          data-banner-type="Menu_Board_TV_Landscape"
          onClick={() => handleBannerClick("Menu_Board_TV_Landscape")}
        >
          

          <div className="banner-content-landscape">
            {title && (
              <div
                className="live-title-landscape"
                style={{
                  fontSize: titleFontSize,
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                {title}
              </div>
            )}

            {loading ? (
              <div className="loading-styling">
                <ClipLoader
                  color={"white"}
                  loading={loading}
                  alignContent="center"
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <div>
                {productDetails?.map((product, index) => {
                  // Calculate price in dollars and format it
                  const priceInDollars = (customPrice || product.price) / 100;
                  const formattedPrice = priceInDollars?.toFixed(2);

                  return (
                    <div key={index} className="Landscape-productContainer">
                      <div className="Landscape-imgPriceContainer">
                        {processedImage && (
                          <img
                            src={processedImage}
                            alt={product.name}
                            className="Landscape-transparent-image"
                          />
                        )}

                        {(customPrice || product.price) && (
                          <div
                            className="Landscape-priceTag"
                            style={{
                              fontSize: priceFontSize,
                              color: priceColor,
                              fontFamily: titleFontFamily,
                            }}
                          >
                            ${customPrice || formattedPrice}
                          </div>
                        )}
                      </div>

                      {product?.name && (
                        <div
                          className="Landscape-nameTag"
                          style={{
                            fontSize: nameFontSize,
                            color: nameColor,
                            fontFamily: titleFontFamily,
                          }}
                        >
                          {product.name}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}

            {(footer || showFooterInput) && (
              <div
                className="footer-style-landscape-live"
                style={{
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                {footer}
              </div>
            )}

            {showTC && (
              <div
                className="T-and-C-landscape-live"
                style={{
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                T & C
              </div>
            )}
          </div>
        </div>

        <h3 className="banner-header">- Portrait size</h3>
        <div
          className="banner-preview portrait"
          style={{
            position: "relative",
            ...previewSizes.PORTRAIT_SIZE,
            backgroundImage: `url(${
              background
                ? background instanceof Blob
                  ? URL.createObjectURL(background)
                  : background
                : placeholderurlpotrait
            })`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          ref={(el) => (imgRefs.current[3] = el)}
          data-banner-type="PORTRAIT_SIZE"
          onClick={() => handleBannerClick("PORTRAIT_SIZE")}
        >
          <div className="banner-content">
            {title && (
              <div
                className="live-title-portrait"
                style={{
                  fontSize: titleFontSize,
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                {title}
              </div>
            )}

            {loading ? (
              <div className="loading-styling">
                <ClipLoader
                  color={"white"}
                  loading={loading}
                  alignContent="center"
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <div className="products-container-mobile">
                {productDetails?.map((product, index) => {
                  // Calculate price in dollars and format it
                  const priceInDollars = (customPrice || product.price) / 100;
                  const formattedPrice = priceInDollars.toFixed(2);

                  return (
                    <div key={index} className="product-details-mobile">
                      {processedImage && (
                        <img
                          src={processedImage}
                          alt={product.name}
                          className="Portrait-transparent-image"
                        />
                      )}
                      {product?.name && (
                        <div
                          className="Portrait-nameTag"
                          style={{
                            fontSize: nameFontSize,
                            color: nameColor,
                            fontFamily: titleFontFamily,
                          }}
                        >
                          {product.name}
                        </div>
                      )}
                      {(customPrice || product.price) && (
                        <div
                          className="Portrait-priceTag"
                          style={{
                            fontSize: priceFontSize,
                            color: priceColor,
                            fontFamily: titleFontFamily,
                          }}
                        >
                          ${customPrice || formattedPrice}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}

            {(footer || showFooterInput) && (
              <div
                className="footer-style-Portrait"
                style={{
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                {footer}
              </div>
            )}

            {showTC && (
              <div
                className="T-and-C-Portrait"
                style={{
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                T & C
              </div>
            )}
          </div>
        </div>

        {/* Mobile NFT Size */}
        <h3 className="banner-header">- Mobile NFT </h3>
        <div
          className="banner-preview portrait"
          style={{
            position: "relative",
            ...previewSizes.Mobile_NFT,
            backgroundImage: `url(${
              background
                ? background instanceof Blob
                  ? URL.createObjectURL(background)
                  : background
                : placeholderurlMobileNFT
            })`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          ref={(el) => (imgRefs.current[4] = el)} // Ensure this is the correct index
          data-banner-type="Mobile_NFT"
          onClick={() => handleBannerClick("Mobile_NFT")}
        >
          <div className="banner-content">
            {title && (
              <div
                className="live-title-Mobile-NFT"
                style={{
                  fontSize: titleFontSizeMobile,
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                {title}
              </div>
            )}

            {loading ? (
              <div className="loading-styling">
                <ClipLoader
                  color={"white"}
                  loading={loading}
                  alignContent="center"
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <div className="products-container-mobile">
                {productDetails?.map((product, index) => (
                  <ProductDetailMobile
                    key={index}
                    product={product}
                    processedImage={processedImage}
                    nameFontSize={nameFontSize}
                    priceFontSizeMobile={priceFontSizeMobile}
                    titleFontSizeMobile={titleFontSizeMobile}
                    nameFontSizeMobile={nameFontSizeMobile}
                    nameColor={nameColor}
                    titleFontFamily={titleFontFamily}
                    customPrice={customPrice}
                    priceFontSize={priceFontSize}
                    priceColor={priceColor}
                  />
                ))}
              </div>
            )}
            <div>
              {(footer || showFooterInput) && (
                <div
                  className="footer-style-NFT"
                  style={{
                    color: titleColor,
                    fontFamily: titleFontFamily,
                  }}
                >
                  {footer}
                </div>
              )}

              {showTC && (
                <div
                  className="T-and-C-NFT"
                  style={{
                    color: titleColor,
                    fontFamily: titleFontFamily,
                  }}
                >
                  T & C
                </div>
              )}
              {generatedQR && (
                <div className="QR-Generator">
                  <QRCode value={generatedQR} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {currentBannerType === "Mobile_NFT" ? (
        <ModelMobileNFT
          show={showModal}
          handleClose={handleCloseModal}
          background={background}
          title={title}
          generatedQR={generatedQR}
          titleColor={titleColor}
          titleFontSize={titleFontSize}
          priceFontSize={priceFontSize}
          titleFontSizeMobile={titleFontSizeMobile}
          priceFontSizeMobile={priceFontSizeMobile}
          nameFontSizeMobile={nameFontSizeMobile}
          footer={footer}
          discription={discription}
          processedImage={processedImage}
          showFooterInput={showFooterInput}
          showTC={showTC}
          nameFontSize={nameFontSize}
          productDetails={productDetails}
          titleFontFamily={titleFontFamily}
          customPrice={customPrice}
          nameColor={nameColor}
          priceColor={priceColor}
          bannerType={currentBannerType}
        />
      ) : currentBannerType === "Menu_Board_TV_Landscape" ? (
        <LandscrapeModel
          show={showModal}
          handleClose={handleCloseModal}
          background={background}
          title={title}
          titleColor={titleColor}
          titleFontSize={titleFontSize}
          priceFontSize={priceFontSize}
          footer={footer}
          processedImage={processedImage}
          showFooterInput={showFooterInput}
          showTC={showTC}
          nameFontSize={nameFontSize}
          productDetails={productDetails}
          titleFontFamily={titleFontFamily}
          customPrice={customPrice}
          nameColor={nameColor}
          priceColor={priceColor}
          bannerType={currentBannerType}
        />
      ) : (
        <Previewmodal
          show={showModal}
          handleClose={handleCloseModal}
          background={background}
          title={title}
          titleColor={titleColor}
          titleFontSize={titleFontSize}
          priceFontSize={priceFontSize}
          footer={footer}
          processedImage={processedImage}
          showFooterInput={showFooterInput}
          showTC={showTC}
          nameFontSize={nameFontSize}
          productDetails={productDetails}
          titleFontFamily={titleFontFamily}
          customPrice={customPrice}
          nameColor={nameColor}
          priceColor={priceColor}
          bannerType={currentBannerType}
        />
      )}
      {background && (
        <Button
          className="reg-btn mb-3 big block button-border"
          onClick={downloadAllBannersAsPng}
          disabled={downloadLoading} // Disable the button while loading
        >
          {downloadLoading ? "Downloading..." : "Download Banners"}
        </Button>
      )}
      {downloadLoading && (
        <div className="loader-container">
          <CustomLoader isLoading={downloadLoading} />
        </div>
      )}{" "}
      {/* Loader */}
    </>
  );
};

export default LivePreview;
