import React, { useEffect, useState } from "react";
import { downloadSizes } from "../constant";
import CleanBG from "../SVG/CleanBG";

function PreviewModal({
  show,
  handleClose,
  background,
  title,
  titleColor,
  titleFontSize,
  priceFontSize,
  footer,
  showFooterInput,
  showTC,
  nameFontSize,
  processedImage,
  productDetails,
  titleFontFamily,
  customPrice,
  nameColor,
  priceColor,
  bannerType,
}) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (show) {
      setVisible(true);
    } else {
      setTimeout(() => setVisible(false), 300); 
    }
  }, [show]);

  if (!visible) return null;

  const styles = downloadSizes[bannerType] || {};
  const productDetailStyles = styles.productDetails || {};

  return (
    <div className={`modelContainer ${bannerType}`}>
      <div className={`modal-overlay ${show ? "show" : "hide"}`} onClick={handleClose}>
        <div
          className={`modal-content-live ${show ? "fade-in" : "fade-out"}`}
          onClick={(e) => e.stopPropagation()}
          style={{
            ...styles,
            display: "flex",
            flexDirection: "column",
            backgroundImage: `url(${  
              background
                ? background instanceof Blob
                  ? URL.createObjectURL(background)
                  : background
                : CleanBG
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div>
            <h1
              style={{
                color: titleColor,
                fontFamily: titleFontFamily,
                fontSize: titleFontSize,
                textAlign: "center",
                marginBottom: "20px",
              }}
              className="titlemodel"
            >
              {title}
            </h1>
          </div>
          <div
            className="product-details-container"
            style={productDetailStyles}
          >
            {productDetails?.map((product, index) => (
              <div
                key={index}
                className="product-details"
                style={{ marginBottom: "10px" }}
              >
                {processedImage && (
                  <img
                    className="img-modal"
                    src={processedImage}
                    alt={product.name}
                  />
                )}
                {product.name && (
                  <p
                    style={{
                      fontSize: nameFontSize,
                      fontFamily: titleFontFamily,
                      color: nameColor,
                      maxWidth: "100%",
                    }}
                    className="name-modal"
                  >
                    {product.name}
                  </p>
                )}
                {(customPrice || product.price) && (
                  <p
                    style={{
                      fontSize: priceFontSize,
                      fontFamily: titleFontFamily,
                      color: priceColor,
                    }}
                    className="price-modal"
                  >
                     ${((customPrice || product.price) / 100).toFixed(2)}
                  </p>
                )}
              </div>
            ))}
          </div>
          <div
            className="footer-container"
            style={{
              color: titleColor,
              fontFamily: titleFontFamily,
            }}
          >
            {(footer || showFooterInput) && (
              <div className="footer-stylemodel">{footer}</div>
            )}
            {showTC && <div className="T-and-C-model">T & C</div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreviewModal;
